* {
  box-sizing: border-box;
  user-select: none;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  background: #f0f0f0;
}
header {
  position: absolute;
  top: 0;
  left: 50%;
  width: 20%;
  transform: translateX(-50%);
  display: flex;
}
footer {
  position: absolute;
  bottom: 40px;
  color: white;
  left: 50%;
  transform: translateX(-50%);
  font-family: Arial, sans-serif;
  font-size: 12px;
  text-align: center;
  width: 100%;
}

button {
  margin: 20px 0;
  padding: 20px 40px;
  border-radius: 50px;
  border: none;
  font-size: calc(1em + 0.690625vw);
  font-weight: bold;
  cursor: pointer;
  transition: all 1.2s ease;
}

button:hover {
  padding: 20px 50px;
  color: white;
  background-color: black;
}

@media only screen and (max-width: 600px) {
  header {
    width: 50%;
  }
}
